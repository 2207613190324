import React, { useState, useEffect } from "react";
import { Wheel } from "react-custom-roulette";
import "./Quiz.scss";
import { useTranslation } from "react-i18next";
import questionSquare from "../../assets/logos/question-square.svg";
import flecheQuiz from "../../assets/logos/fleche-quiz.svg";
import confetti from "canvas-confetti";

const fakeQuestions = [
  {
    question: "Que signifie Halal en jurisprudence islamique ?",
    options: ["Permis", "Interdit", "Obligatoire", "Découragé"],
    response: "Permis",
  },
  {
    question: "Quel est le jour saint dans l'Islam ?",
    options: ["Vendredi", "Samedi", "Dimanche", "Lundi"],
    response: "Vendredi",
  },
  {
    question:
      "Combien de prières quotidiennes les musulmans doivent-ils accomplir ?",
    options: ["3", "4", "5", "6"],
    response: "5",
  },
  {
    question: "Qui est considéré comme le dernier prophète dans l'Islam ?",
    options: ["Moïse", "Jésus", "Mahamed", "Abraham"],
    response: "Mahamed",
  },
];

const QuizData = [
  {
    option: "Histoire",
    style: { backgroundColor: "#406259" },
  },
  { option: "Histoire", style: { backgroundColor: "#609486" } },
  { option: "Histoire", style: { backgroundColor: "#8FBCB0" } },
  { option: "Histoire", style: { backgroundColor: "#D8D9C4" } },
  { option: "Histoire", style: { backgroundColor: "#C5BC99" } },
  { option: "Histoire", style: { backgroundColor: "#BAAF84" } },
  { option: "Histoire", style: { backgroundColor: "#A4965D" } },
  { option: "Histoire", style: { backgroundColor: "#1D342E" } },
];

const App = () => {
  const { t } = useTranslation();
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [showQuiz, setShowQuiz] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [selectedOption, setSelectedOption] = useState("");
  const [answerChecked, setAnswerChecked] = useState(false);
  const [score, setScore] = useState(0);
  const [quizFinished, setQuizFinished] = useState(false);

  const handleWheelClick = () => {
    if (!mustSpin) {
      const newPrizeNumber = Math.floor(Math.random() * QuizData.length);
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
    }
  };

  const handleWheelStop = () => {
    setMustSpin(false);
    setShowQuiz(true);
  };

  const handleOptionChange = (option) => {
    if (!answerChecked) {
      setSelectedOption(option);
    }
  };

  const handleSubmit = () => {
    if (selectedOption) {
      setAnswerChecked(true);
      if (selectedOption === fakeQuestions[currentQuestion - 1].response) {
        setScore(score + 1);
      }
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestion < fakeQuestions.length) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption("");
      setAnswerChecked(false);
    } else {
      setQuizFinished(true);
    }
  };

  // animation
  useEffect(() => {
    if (quizFinished) {
      const defaults = {
        spread: 360,
        ticks: 50,
        gravity: 0,
        decay: 0.94,
        startVelocity: 30,
        colors: ["FFE400", "FFBD00", "E89400", "FFCA6C", "FDFFB8"],
      };

      function shoot() {
        confetti({
          ...defaults,
          particleCount: 40,
          scalar: 1.2,
          shapes: ["star"],
        });

        confetti({
          ...defaults,
          particleCount: 10,
          scalar: 0.75,
          shapes: ["circle"],
        });
      }
      [
        0, 300, 600, 800, 1200, 1700, 2100, 2800, 3200, 3800, 4300, 5000, 5400,
        6000, 6300, 6800, 7200,
      ].forEach((item) => {
        setTimeout(shoot, item);
      });
    }
  }, [quizFinished]);

  if (quizFinished) {
    return (
      <div className="quiz-finished-container">
        <div className="quiz-finished-overlay">
          <div className="score-message">
            <h2>Quiz Finished</h2>
            <p>
              YOUR SCORE : {score}/{fakeQuestions.length}
            </p>
            <button onClick={() => window.location.reload()}>Retry</button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="pages-position">
      <div className="pages-header">
        <span className="title-page">{t("Quiz.TITLE")}</span>
      </div>
      {!showQuiz ? (
        <div className="quiz-body">
          <div className="quiz-text">
            <p>{t("Quiz.READY_TEXT")}</p>
          </div>
          <div className="wheel-container" onClick={handleWheelClick}>
            <Wheel
              className="wheel"
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={QuizData}
              outerBorderColor={"#f2f2f2"}
              outerBorderWidth={4}
              innerBorderColor={"#D9D9D9"}
              radiusLineColor={"#dedede"}
              radiusLineWidth={1}
              fontSize={17}
              textColors={["#FFFFFF"]}
              onStopSpinning={handleWheelStop}
              spinDuration={0.8}
              pointerProps={{ src: flecheQuiz }}
            />
          </div>
        </div>
      ) : (
        // Quiz avec questions
        <div className="quiz-container">
          <div className="header">
            <span className="question-number">
              Q {currentQuestion}/{fakeQuestions.length}
            </span>
            <div className="progress-bar">
              <div
                className="progress"
                style={{
                  width: `${(currentQuestion / fakeQuestions.length) * 100}%`,
                }}
              ></div>
            </div>
          </div>
          <div className="image-container">
            <img
              src={questionSquare}
              alt="Question"
              className="question-image"
            />
          </div>
          <div className="options-container">
            <h2>{fakeQuestions[currentQuestion - 1].question}</h2>
            {fakeQuestions[currentQuestion - 1].options.map((option) => (
              <button
                key={option}
                onClick={() => handleOptionChange(option)}
                className={`option-button ${
                  selectedOption === option ? "selected" : ""
                } ${
                  answerChecked
                    ? option === fakeQuestions[currentQuestion - 1].response
                      ? "correct"
                      : option === selectedOption
                      ? "incorrect"
                      : ""
                    : ""
                }`}
                disabled={answerChecked && selectedOption !== option}
              >
                {option}
              </button>
            ))}
          </div>
          <div className="h-14">
            {answerChecked && (
              <div className="next-container" onClick={handleNextQuestion}>
                <span className="next-arrow cursor-pointer">
                  {currentQuestion < fakeQuestions.length
                    ? "Suivant >>"
                    : "Terminer"}
                </span>
              </div>
            )}
          </div>
          {/* Submit button */}
          <button
            className="submit-button"
            onClick={handleSubmit}
            disabled={!selectedOption} // Désactiver seulement si aucune option n'est sélectionnée
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default App;
